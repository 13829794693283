<template>
  <div>
    <div class="banner" :style="getBanner()">
      <div class="container">
        <div class="banner-wrapper">
          <div class="mobile-banner">
            <img alt="" class="corner" src="@/assets/icons/slice-top.png">
          </div>
          <div class="banner-content">
            <h1 class="text-default w-40">{{ $t('welcomeToThe') }}</h1>
            <h1 class="text-bold-700 ">{{ $t('refugeeEmployability') }}</h1>
            <h1 class="text-bold-700">{{ $t('programme') }}</h1>

            <p class="text-bold w-40">{{ $t('preparingRefugeesForWorkAndLifeInSouthwestEngland') }}
              <span class="responsive-text">{{ $t('InSouthwestEngland-home') }}</span></p>

            <div class="buttons">
              <button id="ApplyTodayWelcome" class="btn-primary" @click="scrollToForm">{{ $t('applyToday2') }}</button>
              <router-link id="ReferSomeoneWelcome" class="btn-secondary" to="/refer-someone">{{ $t('referSomeone2') }}</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="languages">
        <div class="language-block">
          <label class="language-tile">
            <span>{{ $t('englishLanguage') }}</span>
            <input v-model="selectedLanguage" class="radio-btn" name="language" type="radio" value="en"
                   @change="changeLanguage">
          </label>
          <label class="language-tile">
            <span>{{ $t('pashto2') }}</span>
            <input v-model="selectedLanguage" class="radio-btn" name="language" type="radio" value="ps"
                   @change="changeLanguage">
          </label>
        </div>

        <div class="language-block">
          <label class="language-tile">
            <span>{{ $t('arabic2') }}</span>
            <input v-model="selectedLanguage" class="radio-btn" name="language" type="radio" value="ar"
                   @change="changeLanguage">
          </label>
          <label class="language-tile">
            <span>{{ $t('dari2') }}</span>
            <input v-model="selectedLanguage" class="radio-btn" name="language" type="radio" value="prs"
                   @change="changeLanguage">
          </label>
        </div>
      </div>

    </div>

    <div class="container">
      <div class="map-content">
        <img :alt="$t('map')" class="map-image" src="@/assets/images/map.png">

        <div class="map-info">
          <h1>{{ $t('preparingRefugeesForWorkAndLifeInEngland') }}</h1>

          <p class="text-black">
            {{ $t('getIndividualSupportAndALearningPlanFromYourOwnCas') }}
          </p>

          <p class="text-black">
            {{ $t('theRefugeeEmployabilityProgrammeCanHelpYouWith') }}
          </p>

          <div class="info-block">
            <div
                :class="[{'scale': selectedLanguage === 'ar' || selectedLanguage === 'ps' || selectedLanguage === 'prs'}]"
                class="icons">
              <img :alt="$t('done')" src="@/assets/icons/done_all.svg">
              <p :class="[{'scale': selectedLanguage === 'ar' || selectedLanguage === 'ps' || selectedLanguage === 'prs'}]">
                {{ $t('findingEmployment') }}
              </p>

            </div>

            <p class="text-black">
              {{ $t('buildYourSkillsToFindMeaningfulWorkIncludingSelfem') }}
            </p>
          </div>

          <div class="info-block">
            <div
                :class="[{'scale': selectedLanguage === 'ar' || selectedLanguage === 'ps' || selectedLanguage === 'prs'}]"
                class="icons">
              <img :alt="$t('done')" src="@/assets/icons/done_all.svg">
              <p :class="[{'scale': selectedLanguage === 'ar' || selectedLanguage === 'ps' || selectedLanguage === 'prs'}]">
                {{ $t('settlingIn') }}
              </p>

            </div>
            <p>
              {{ $t('learnHowToNavigateUkLifeIndependently') }}
            </p>
          </div>


          <div class="info-block">
            <div
                :class="[{'scale': selectedLanguage === 'ar' || selectedLanguage === 'ps' || selectedLanguage === 'prs'}]"
                class="icons">
              <img :alt="$t('done')" src="@/assets/icons/done_all.svg">
              <p :class="[{'scale': selectedLanguage === 'ar' || selectedLanguage === 'ps' || selectedLanguage === 'prs'}]">
                {{ $t('learningEnglish') }}
              </p>

            </div>
            <p>
              {{ $t('buildConfidenceToCommunicateBetter') }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <!--    <div class="container">-->
    <!--      <div class="opportunities-cards">-->
    <!--        <OpportunityCard v-for="card in opportunitiesCards"-->
    <!--                         :card="card"-->
    <!--        >-->
    <!--        </OpportunityCard>-->

    <!--&lt;!&ndash;        <test-popover/>&ndash;&gt;-->
    <!--      </div>-->
    <!--    </div>-->
    <new-opportunity-card :selectedLanguage="selectedLanguage"/>

    <test-new-tooltips/>

    <FormBlock id="form" :selectedLanguage="selectedLanguage"/>

    <div class="work-program">
      <div class="container">
        <div class="wrapper">
          <div class="how-works">
            <h1>{{ $t('howTheProgrammeWorks') }}
            </h1>
            <p>
              {{ $t('hereIsHowTheRefugeeEmployabilityProgrammeWorksAndW') }}
            </p>

            <img :alt="$t('program')" src="@/assets/images/program-works.png">
          </div>

          <div class="accordions">
            <Accordion :opened="true" :selectedLanguage="selectedLanguage">
              <template v-slot:title="{ selectedLanguage }">
                <h4>
                  <span>{{ $t('one') }}</span>
                  {{ $t('checkYourEligibility') }}</h4>
              </template>
              <template v-slot:content>

                <ul style="margin-left: 1.25rem" :class="[{'right-side-markers': selectedLanguage === 'ar' || selectedLanguage === 'ps' || selectedLanguage === 'prs'}]">
                  <li>
                    <p>{{ $t('betweenTheAgeOf18And66StatePensionAge') }}</p>
                  </li>
                  <li>
                    <p>{{ $t('livingInTheSouthWestOfEngland') }}
                      <span>
                        <a class="link-modal" href="https://www.gov.uk/find-local-council" target="_blank"><b>
                          {{ $t('clickHereToFindYourLocalCouncilByPostcode') }}</b>
                        </a>
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>{{ $t('activelySeekingWorkAndNotInFulltimeEmployment16Hou') }}</p>
                  </li>
                  <li>
                    <p>{{ $t('mustEitherHaveIndefiniteLeaveToRemainAlsoKnownAsSe') }}</p>
                  </li>
                  <li>
                    <p>
                      {{ $t('youMustHaveArrivedInTheUkByCertainRoutesClickHereF') }}
                      <span>
                        <a class="link-modal" @click="openModal">
                          <b>{{$t('clickHereForMoreDetails')}}</b>
                        </a>
                      </span>
                    </p>
                  </li>
                </ul>

                <Modal
                    :is-modal-visible="isModalVisible"
                    @close="closeModal"
                >
                  <template v-slot:modal>
                    <div class="modal-content">
                      <div class="block">
                        <p>
                          {{ $t('youMustHaveArrivedInTheUkUnderOneOfTheFollowingRou') }}
                        </p>


                        <ul :class="[{'modal-accordion': selectedLanguage === 'ar' || selectedLanguage === 'ps' || selectedLanguage === 'prs'}]">
                          <li>
                            {{ $t('ukResettlementSchemeUkrs') }}
                          </li>
                          <li>
                            {{ $t('afghanCitizensResettlementSchemeAcrs') }}
                          </li>
                          <li>
                            {{ $t('afghanRelocationsAndAssistancePolicyArap') }}
                          </li>
                          <li>
                            {{ $t('communitySponsorshipSchemeIfYouArrivedOnOrAfter25F') }}
                          </li>
                        </ul>
                      </div>

                      <div class="block">
                        <p>{{ $t('youCanGetExtraSupportFocusedOnIntegrationAndEnglis') }}</p>

                        <ul :class="[{'modal-accordion': selectedLanguage === 'ar' || selectedLanguage === 'ps' || selectedLanguage === 'prs'}]">
                          <li>{{ $t('refugeePermissionToStayIfYouWereGrantedPermissionT') }}
                          </li>
                          <li>{{ $t('mandateResettlementSchemeIfYouArrivedOnOrAfter25Fe') }}</li>
                          <li>{{ $t('refugeeFamilyReunionIfYourSponsoringRefugeeIsEligi') }}</li>
                        </ul>
                      </div>

                      <div class="block">
                        <p>
                          {{ $t('forMoreInformationPleaseReferToTheUkGovernmentRepP') }}
                        </p>
                        <p>
                          {{ $t('emailRepswrescueorg') }}
                        </p>
                        <p>
                          {{ $t('freeTelephone08082940124') }}
                        </p>
                      </div>

                    </div>
                  </template>
                </Modal>
              </template>
            </Accordion>

            <Accordion :selectedLanguage="selectedLanguage">
              <template v-slot:title="{ selectedLanguage }">
                <h4>
                  <span>{{ $t('two') }}</span>
                  {{ $t('meetYourCaseWorker') }}</h4>
              </template>
              <template v-slot:content>
                <p>
                  {{ $t('yourAssignedCaseManagerWillArrangeAnIntroductionMe') }}
                </p>

                <p>{{ $t('inOrderToBetterSupportYouYourCaseManagerWillNeedTo') }}</p>
              </template>
            </Accordion>

            <Accordion :selectedLanguage="selectedLanguage">
              <template v-slot:title="{ selectedLanguage }">
                <h4>
                  <span>{{ $t('three') }}</span>
                  {{ $t('setYourGoalsTogether') }}</h4>
              </template>
              <template v-slot:content>
                <p>
                  {{ $t('workWithYourCaseManagerYourPersonalDevelopmentPlan') }}
                </p>
                <!--                <p>{{ $t('youWillAgreeWithYourCaseManagersOnYourNextMileston') }}</p>-->
                <!--                <p>{{ $t('buildTogetherWithYourCaseManagerATailoredPersonalD') }}</p>-->
              </template>
            </Accordion>

            <Accordion :selectedLanguage="selectedLanguage">
              <template v-slot:title="{ selectedLanguage }">
                <h4>
                  <span>{{ $t('four') }}</span>
                  {{ $t('accessSupportNeeded') }}
                </h4>
              </template>
              <template v-slot:content>
                <p>
                  {{ $t('thePersonalisedPersonalDevelopmentPlanWillOutlineW') }}
                </p>
                <br>
                <p>
                  {{ $t('yourPlanWillIncludeTheServicesThatCanBeAccessedInt') }}
                </p>
              </template>
            </Accordion>


            <Accordion :selectedLanguage="selectedLanguage">
              <template v-slot:title="{ selectedLanguage }">
                <h4>
                  <span>{{ $t('five') }}</span>
                  {{ $t('regularCheckinOnProgress') }}
                </h4>
              </template>
              <template v-slot:content>
                <p>
                  {{ $t('yourCaseManagerWillContactYouAtLeastEvery10Working') }}
                </p>
                <br>
                <p>
                  {{ $t('weCanSupportYouForAPeriodUpTo18MonthsEvenAfterYouF') }}
                </p>
              </template>
            </Accordion>

            <div class="button-element">
              <button id="ApplyHowTheProgramme" class="btn-primary" @click="scrollToForm()">{{ $t('applyToday') }}</button>
              <button id="TrainingEventsHowTheProgramme" class="btn-secondary" @click="openInNewTab('/events')">{{ $t('setTrainings&Events') }}</button>
            </div>


          </div>

        </div>
      </div>
    </div>


    <div class="download-resources">
      <div class="container">
        <div class="wrapper">
          <div class="info">
            <h1>{{ $t('downloadableResources') }}</h1>
            <p>{{ $t('clickHereToAccessFlyersAboutTheProjectAndOtherMate') }}</p>

            <router-link class="btn-secondary" to="/leaflets">
              <img :alt="$t('download')" src="@/assets/icons/download.svg">
              {{ $t('downloadHere') }}
            </router-link>
          </div>
          <img :alt="$t('download')" class="download-image" src="@/assets/images/download.png">
        </div>
      </div>
    </div>

    <div class="container">
      <top-stories :stories="stories" :loading="loading" class="mt"/>
    </div>

    <div class="subscribe">
      <p class="description">{{ $t('subscribeForSomething') }}</p>
      <router-link id="SubscribeMainPage" class="btn-secondary" :to="{ path: '/contact', hash: '#subscribeBlock', query: { scroll: 'true' } }">
        {{ $t('subscribe') }}
      </router-link>
    </div>


  </div>
</template>

<script>

import Accordion from "@/components/Accordion.vue";
import OpportunityCard from "@/components/OpportunityCard.vue";
import FormBlock from "@/components/FormBlock.vue";
import TestPopover from "@/components/TestPopover.vue";
import NewOpportunityCard from "@/components/NewOpportunityCard.vue";
import JotFormComponent from "@/components/JotFormComponent.vue";
import languageService from "@/services/language";
import GoogleTranslate from "@/components/GoogleTranslate.vue";
import Modal from "@/components/Modal.vue";
import {EventBus} from "@/services/eventBus";
import TestNewTooltips from "@/components/testNewTooltips.vue";
import TopStories from "@/components/TopStories.vue";
import axios from "axios";
import {fetchStories} from "@/main";

export default {
  name: 'Home',
  data() {
    return {
      isModalVisible: false,
      selectedLanguage: 'en',
      banner:{},
      stories: [],
      loading: true,

    }
  },
  components: {
    TestNewTooltips,
    Modal,
    GoogleTranslate,
    NewOpportunityCard,
    TestPopover,
    FormBlock,
    OpportunityCard,
    Accordion,
    JotFormComponent,
    TopStories
  },
  methods: {
    scrollToForm() {
      document.getElementById('form').scrollIntoView({
        behavior: 'smooth'
      });
    },
    openInNewTab(url) {
      const fullUrl = `${window.location.origin}${url}`;
      window.open(fullUrl, '_blank');
    },
    changeLanguage() {
      languageService.changeLang(this.selectedLanguage, this.$i18n);
      EventBus.$emit('languageChangedHome', this.selectedLanguage);
    },
    openModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    async initBanner(){
      await axios.get(process.env.VUE_APP_API_URL + "/images/main").then((response) => {
        this.banner = response.data;
      });
    },
    loadStories() {
      fetchStories()
          .then(data => {
            this.stories = data;
            this.loading = false;
          })
          .catch(error => {
            console.error('Error fetching stories:', error);
            this.loading = false;
          });
    },
    getBanner() {
      if (this.banner.url) {
        return {
          backgroundImage: 'url(' + process.env.VUE_APP_API_URL + this.banner.url + ')'
        }
      } else {
        return {}
      }
    }
  },
  watch: {
    selectedLanguage(newLang, oldLang) {
      if (newLang !== oldLang) {
        this.changeLanguage(newLang);
      }
    }
  },
  async mounted() {
    await this.initBanner();
    await this.loadStories();
    this.selectedLanguage = this.$i18n.locale
    EventBus.$on('languageChangedHeader', (newLanguage) => {
      this.selectedLanguage = newLanguage;
    });
  },
  // computed() {
  //   this.selectedLanguage = this.$i18n.locale
  // }
}
</script>

<style lang="scss" scoped>

.mt{
  margin: 120px 0 120px 0 !important;
  width: auto!important;
  @media (max-width: 1250px) {
    margin: 120px 1rem 120px 1rem !important;
  }
}
.reverse {
  flex-direction: row-reverse;
  display: flex;
  gap: 1rem;
}

.scale {
  transform: scale(-1, 1)
}

ul.right-side-markers {
  list-style: none; /* Убираем стандартные маркеры */
  padding: 0;
}


ul.right-side-markers li {
  position: relative; /* Для позиционирования псевдоэлемента */
  padding-right: 20px; /* Отступ справа для маркера */
}

ul.right-side-markers li::before {
  content: '•'; /* Символ маркера */
  position: absolute;
  right: 0; /* Позиционирование справа */
  top: 0;
}

.modal-content {
  ul {
    //width: 70%;
    list-style: none;
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    @media (max-width: 1380px) {
      width: 100%;
    }
  }

  li {
    font-size: var(--main-font-size);
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 8px;

    a {
      color: $main-color;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  @media (max-width: 1380px) {
    width: 100%;
    margin-bottom: 64px;

    h1 {
      font-size: calc(var(--main-font-size) + 8px);
    }
  }
}

.w-40 {
  width: 35%;
  @media (max-width: 1380px) {
    width: 100%;
  }
}

.link-modal {
  color: #034694;
  text-decoration: underline;
  cursor: pointer;
}

.banner {
  position: relative;

  .corner-top {
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1380px) {
    background-image: none !important;
    height: auto !important;
  }
}

.language-block {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (max-width: 1380px) {
    gap: 5px;
    width: calc(50% - 2.5px);
  }
}

@media (max-width: 1700px) {
  .responsive-text {
    display: block;
  }
}

.banner-wrapper {
  display: flex;
  flex-direction: column;

  .mobile-banner {
    display: none;
    position: relative;
    width: 100%;
    height: auto;
    background-image: url("@/assets/images/ZahraRahimi-NEW.jpg");
    min-height: 400px;
    max-height: 600px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    @media (max-width: 1380px) {
      background-position: 100% 25%;
    }
    @media (max-width: 576px) {
      background-position: center;
    }

    .corner {
      width: 128px;
      position: absolute;
      top: -2px;
      @media (max-width: 770px) {
        width: 70px;
      }
    }

    img {
      width: 100%;
      max-height: 600px;
      object-fit: cover;
      object-position: top;
    }

    @media (max-width: 1224px) {
      display: flex;
    }
  }

  .banner-content {
    a {
      text-decoration: none;
    }

    .buttons {
      button, a {
        font-size: calc(var(--main-font-size) + 2px);
      }

      a {
        width: fit-content;
      }
    }

    .btn-primary {
      width: 243px;
      padding-left: 20px;
      padding-right: 20px;
      border: 1.6px;
    }

    @media (max-width: 1380px) {
      background: rgba(146, 197, 235, 1);
      padding: 16px;

      h1 {
        font-size: calc(var(--main-font-size) + 8px);
      }

      p {
        font-size: var(--main-font-size);
      }

      .buttons {
        margin-top: 32px;
        flex-direction: column;

        button, a {
          width: 100%;
          font-size: var(--main-font-size);
          padding: 15px 20px;
          text-align: center;

        }
      }
    }
  }
}

.languages {
  margin: 48px 0 120px 0;
  display: flex;
  flex-wrap: wrap;
  column-gap: 24px;
  row-gap: 16px;


  .language-tile {
    width: 600px;
    font-weight: 600;
    height: 91px;
    border-radius: 8px;
    padding: 32px;
    background-color: rgba(169, 219, 210, 1);
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      font-size: var(--main-font-size);
    }

    &:hover {
      cursor: pointer;
    }
  }

  @media (max-width: 1380px) {
    padding: 0 16px;
    margin: 24px 8px;
    gap: 5px;
    justify-content: center;

    .language-tile {
      width: 100%;
      min-height: 40px;
      height: fit-content;
      padding: 10px;

      span {
        font-size: calc(var(--main-font-size) - 3px);
      }
    }
  }

  @media (max-width: 375px) {
    padding: 0 !important;
  }
}

.map-content {
  display: flex;
  margin-bottom: 120px;

  .map-info {
    padding: 0 25px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;


    h1 {
      font-weight: 700;
      font-size: calc(var(--main-font-size) + 32px);
      color: $main-color;
      line-height: 124%;
    }

    p {
      font-size: calc(var(--main-font-size) + 2px);
      line-height: 27px;
      color: $main-color;

      &.text-black {
        color: #000000;
      }
    }

    .info-block {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .icons {
        display: flex;
        align-items: center;
        gap: 8px;
        font-weight: 600;
      }
    }

    @media (max-width: 1380px) {
      padding: 0;
      h1 {
        font-size: calc(var(--main-font-size) + 8px);
      }

      p {
        font-size: var(--main-font-size);
      }
    }
  }

  @media (max-width: 1380px) {
    flex-direction: column;
    padding: 0 16px;
    align-items: center;

    .map-image {
      width: 328px;
      height: 328px;
      margin-bottom: 24px;
    }
  }
}

.subscribe {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f0f4f8;
  gap: 32px;
  padding: 64px 0;

  .description {
    color: #1C1B1F;
    font-family: Poppins, sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 124%; /* 59.52px */
    letter-spacing: 1.44px;
  }

  .btn-secondary {
    color: #1C1B1F;
    border-color: black;
    text-decoration: none;
    width: 400px;

    &:hover {
      background-color: #1C1B1F;
      color: #fff;
    }
  }

  @media screen and (max-width: 1380px) {
    flex-direction: column;
    gap: 16px;
    padding: 32px 16px;

    .description {
      font-size: 24px;
    }

    .btn-secondary {
      width: 100%;
    }
  }

}

.opportunities-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  margin-bottom: 120px;

  @media (max-width: 1380px) {
    flex-direction: column;
    align-items: center;
    padding: 0 16px;
  }
}

.work-program {
  height: 983px;
  display: flex;
  align-items: center;
  overflow: auto;

  .how-works {
    width: 32%;
    color: $main-color;
    font-weight: 700;
    display: flex;
    flex-direction: column;

    h1 {
      font-size: calc(var(--main-font-size) + 32px);
      margin-bottom: 13px;
      line-height: 124%;
    }

    p {
      font-size: calc(var(--main-font-size) + 2px);
      margin-bottom: 30px;
      font-weight: 400;
    }

    @media (max-width: 1380px) {
      width: 100%;
      margin-bottom: 32px;

      h1 {
        font-size: calc(var(--main-font-size) + 8px);
      }

      p {
        font-size: var(--main-font-size);
      }

      img {
        width: 322px;
        height: 371px;
        align-self: center;
      }
    }
  }

  .accordions {
    width: 66%;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .btn-primary {
      font-size: calc(var(--main-font-size) + 2px);
    }

    p {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }

    h4 {
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 27px */
      letter-spacing: -0.18px;
      width: 100%;
      @media (max-width: 720px) {
        font-size: 16px;
      }

      span {
        font-size: 24px;
        padding-right: 16px;
        @media (max-width: 720px) {
          font-size: 20px;
        }
      }
    }

    @media (max-width: 1380px) {
      width: 100%;

      .btn-primary {
        width: 100% !important;
      }
    }

    .btn-primary {
      width: 392px;
    }
  }

  @media (max-width: 1380px) {
    height: auto;
    padding: 120px 16px;

    .wrapper {
      flex-direction: column;
    }
  }
}

.wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.button-element {
  display: flex;
  flex-direction: row;
  gap: 32px;
  .btn-secondary {
    width: 392px;
    color: #184892;
    font-family: Arial, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 27px */
    text-transform: uppercase;

    &:hover {
      background-color: #184892;
      color: #FFFFFF;
    }

    &:focus {
      background-color: #FFFFFF;
      color: #184892;
    }
  }
  @media (max-width: 1380px) {
    flex-direction: column;
    gap: 16px;
    .btn-secondary {
      width: 100%;
    }
  }
}

.download-resources {
  height: 467px;
  background-color: #A9DBD2;
  display: flex;
  align-items: center;

  @media (max-width: 1380px) {
    padding: 32px 16px;
    height: auto;
    .wrapper {
      flex-direction: column-reverse;
      align-items: center;
    }
    .download-image {
      width: 122px;
      height: 140px;
    }
  }

  .info {
    h1 {
      font-size: calc(var(--main-font-size) + 32px);
      font-weight: 700;
      margin-bottom: 16px;
    }

    p {
      font-size: calc(var(--main-font-size) + 2px);
      font-weight: 400;
      line-height: 27px;
      margin-bottom: 32px;
    }

    @media (max-width: 1380px) {
      h1 {
        margin-top: 32px;
        font-size: calc(var(--main-font-size) + 8px);
      }
    }
  }


  .btn-secondary {
    width: 60%;
    font-size: calc(var(--main-font-size) + 2px);
    font-weight: 700;
    padding: 16px 90px;
    gap: 10px;
    line-height: 16px;
    border-color: black;
    color: black;
    text-decoration: none;
    @media (max-width: 1380px) {
      width: 100%;
      font-size: calc(var(--main-font-size) + 2px);
      padding: 16px 40px;
    }

    &:hover {
      border-color: #034694;
      background-color: #034694;
      color: #FFFFFF;

      img {
        filter: brightness(0) invert(1);
      }
    }
  }
}

//.modal-accordion {
//  li {
//    flex-direction: row-reverse;
//  }
//}

</style>
